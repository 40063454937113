import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./Login.css";
import Footer from "../Footer.jsx";
import oldLogo from "./oldLogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Modal, Card, Alert } from "react-bootstrap";

// main component
export default function Login({ setToken }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  // post login credentials to API and determine if valid
  const loginUser = async (credentials) => {
    try {
      const response = await fetch("/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });
      console.log(response);
      if (!response.ok) {
        throw new Error("Failed to Authenticate");
      }
      return response.json();
    } catch (err) {
      console.error(err.message);
      setErrorMessage("Invalid Username/Password");
      setShowAlert(true);
      return { error: "Invalid Username or Password" };
    }
  };

  //setup state hooks for variables
  document.title = "JMOB Online Ordering";

  // manipulate browser history queue useHistory state hook
  const history = useHistory();

  //function to handle click of login button
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password,
    });

    console.log(token);

    //check token info if not valid popup error otherwise send user to index of JMO Site Calendar.
    if (!token.token || token.error) {
      setErrorMessage("Invalid Username/Password");
    } else {
      setToken(token);
      localStorage.setItem("userToken", token);

      localStorage.removeItem("selectedValue");
      history.push("/");
    }
  };

  //function to reset error message to null when dismissed
  const handleClose = () => setErrorMessage(null);

  return (
    <>
      <div className="login-logo">
        <img src={oldLogo} alt="logo" />
      </div>

      <div className="container">
        <Alert
          show={showAlert}
          variant="danger"
          onClose={() => setShowAlert(false)}
          dismissible
        >
          Unsuccessful login. Please try again.
        </Alert>
      </div>
      <div className="login-wrapper">
        <h2>Online Ordering - Log In</h2>
      </div>
      {/* building the form inputs within a card so it looks better */}
      <div className="d-flex justify-content-center align-items-center vh-50">
        <Card style={{ width: "25rem" }}>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="emailInput">
                <Form.Label>User:</Form.Label>
                <Form.Control
                  required
                  type="email"
                  bg="Secondary "
                  placeholder="Enter username"
                  onChange={(e) => setUserName(e.target.value)}
                  isInvalid={errorMessage !== null}
                />
              </Form.Group>
              <Form.Group className="mb-3" constrolId="passwordInput">
                <Form.Label>Password:</Form.Label>
                <Form.Control
                  required
                  type="password"
                  bg="Secondary "
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={errorMessage !== null}
                />
              </Form.Group>
              <Button
                variant="dark"
                type="submit"
                onClick={handleSubmit}
                className="button-to-the-right"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>

      {/* setup error popup modal important to note here the show={} function
          this error message modal will only be displayed if there is data in the errorMessage variable
      */}
      <Modal
        className="failure-modal"
        centered
        show={errorMessage !== null}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
