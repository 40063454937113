import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Form,
  Button,
  Modal,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "./Orders.css";
import OrderDetail from "./OrderDetail";

function Orders() {
  //setup variables to collect data from query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedDate = queryParams.get("orderDate");
  const driverID = queryParams.get("driverID");
  const companyID = queryParams.get("companyID");
  const isReadOnly = queryParams.get("RO");

  //setup state hooks for state variables when these are changed
  //the data will be updated and the entire component will be able to access
  //note the default use case value within () important if you want to set a flag default to true / false etc
  const [orderSummary, saveOrderSummary] = useState([]);
  const [cutOffDate, saveCutOffDate] = useState("");
  const [amendedDate, saveAmendedDate] = useState("");
  const [outputDate, saveOutputDate] = useState("");
  //function to do PUT request on current order to update the ViewDate field
  async function updateViewDate() {
    try {
      const response = await fetch(
        `/OrdersViewed?orderDate=${selectedDate}&driverID=${driverID}&companyID=${companyID}`,
        {
          method: "PUT",
        }
      );
      const data = await response.json();
      console.log("test updateViewDate : " + data);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  //function to get order summary info for the current selected date

  // TODO
  async function getOrderSummary() {
    try {
      const response = await fetch(
        `/OrdersData?orderDate=${selectedDate}&driverID=${driverID}&companyID=${companyID}`
      );
      const data = await response.json();

      console.log("data[0] : " + JSON.stringify(data[0]));

      //calling the state hook functions to update the global variable
      saveOrderSummary(data[0]);
      saveCutOffDate(data[0].CutOffDate);
      saveAmendedDate(data[0].AmendedDate);
      console.log("amend date : " + data[0].AmendedDate);
      saveOutputDate(data[0].OutputDate);

      // getting the cutoffdate from data returned and checking it vs the current date of the client
      // if the current date is greater than cutoff set passed to true will we affect OrderDetail functionality
      // const cutoffDate = new Date(data[0].CutOffDate);
      // const currentDate = new Date();

      // const response2 = await fetch(`/Calendar?driverID=${driverID}&companyID=${companyID}`);
      // const data2 = await response2.json();

      // console.log("data2 : "+JSON.stringify(data2));

      if (data[0].ReadOnlyStatus === 1) {
        setIsCutoffPassed(true);
      } else {
        setIsCutoffPassed(false);
      }

      //check if there's a comment from JMOB if so set it to the
      //JmobMessage global variable and show the popup modal
      if (data[0].JMOBComment !== null && data[0].JMOBComment !== "") {
        setJmobMessage(data[0].JMOBComment);
        setShowModal(true);
      }

      //Check the Readonly status for the order :
      //this needs to be confirmed as some records have ReadOnlyStatus or AmendedQtyReadOnlyStatus not both
      if (
        data[0].ReadOnlyStatus === 1 ||
        data[0].AmendedQtyReadOnlyStatus === 1
      ) {
        setReadOnlyStatus("true");
      } else {
        setReadOnlyStatus("false");
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  // function called automatically by the framework when any of the variables changed in the []
  // if statement to check that all of the values have real data ie not null and then execute functions
  // without this check you can get unexpected functionality as the app might try to render before data is collected
  useEffect(() => {
    if (selectedDate && driverID && companyID) {
      updateViewDate();
      getOrderSummary();
      getOrderDetail();
    }
  }, [selectedDate, driverID, companyID]);

  // more use state hooks
  // these dont have to be placed at the top of the function they can be added as needed
  // as long as they're not within another function. If placed within another function
  //the function must be given a leading capital name and the variables wont be globally accessible
  const [isCutoffPassed, setIsCutoffPassed] = useState(false);
  const [jmobMessage, setJmobMessage] = useState("");
  const [readOnlyStatus, setReadOnlyStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  //function used to close modal , called from the onClick handler
  const handleClose = () => {
    setShowModal(false);
  };

  const [detailLoaded, setDetailLoaded] = useState(false);
  const getOrderDetail = async () => {
    try {
      const response = await fetch(
        `/OrderDetail?orderDate=${selectedDate}&driverID=${driverID}&companyID=${companyID}`
      );
      const data = await response.json();
      console.log("test getOrderDetail : " + JSON.stringify(data));

      if (data.recordset === null) {
        alert(JSON.stringify(data));
      } else {
        setDetailLoaded(true);
        setFormData(data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const [formData, setFormData] = useState("");
  return (
    <div className="container-xxl">
      {jmobMessage !== "" && (
        <div className="ms-1 mb-3 d-flex justify-content-end">
          <Button
            className="d-md-none"
            size="sm"
            variant="dark"
            onClick={() => setShowModal(true)}
          >
            View Notes
          </Button>
          <Button
            className="d-none d-md-block"
            size="md"
            variant="dark"
            onClick={() => setShowModal(true)}
          >
            View Notes
          </Button>
        </div>
      )}

      <Modal show={showModal} onHide={handleClose} style={{ zIndex: 9999 }}>
        <Modal.Header closeButton>
          <Modal.Title>Message from JMOB</Modal.Title>
        </Modal.Header>
        <Modal.Body>{jmobMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* CR: Responsive header for order dates */}
      <Card bg="">
        {/* <Card.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h5> Order Info </h5>
            {jmobMessage !== '' && (
              
              <div className='button-group-left'>
              <Button variant='info' onClick={() => setShowModal(true)}> 
              View Notes
              </Button>
              </div>
              )}
            </Card.Header> */}
        <Card.Body>
          <table>
            <tbody>
              <div className="d-sm-none">
                <tr>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Date:</span>{" "}
                    {new Date(selectedDate).toLocaleDateString()}
                  </td>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Cut Off:</span>{" "}
                    {new Date(cutOffDate).toLocaleString("en-GB", {
                      timeZone: "UTC",
                    })}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Confirmed:</span>{" "}
                    {outputDate
                      ? new Date(outputDate).toLocaleString("en-GB", {
                          timeZone: "UTC",
                        })
                      : ""}
                  </td>
                  <td>
                    <span style={{ fontWeight: 600 }}>Your last Checkout:</span>{" "}
                    {amendedDate
                      ? new Date(amendedDate).toLocaleString("en-GB", {
                          timeZone: "UTC",
                        })
                      : ""}
                  </td>
                </tr>
              </div>
              <div className="d-none d-sm-block d-md-none">
                <tr>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Date:</span>{" "}
                    {new Date(selectedDate).toLocaleDateString()}
                  </td>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Cut Off:</span>{" "}
                    {new Date(cutOffDate).toLocaleString("en-GB", {
                      timeZone: "UTC",
                    })}
                  </td>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Confirmed:</span>{" "}
                    {outputDate
                      ? new Date(outputDate).toLocaleString("en-GB", {
                          timeZone: "UTC",
                        })
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: 600 }}>Your last Checkout:</span>{" "}
                    {amendedDate
                      ? new Date(amendedDate).toLocaleString("en-GB", {
                          timeZone: "UTC",
                        })
                      : ""}
                  </td>
                </tr>
              </div>
              <div className="d-none d-md-block">
                <tr>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Date:</span>{" "}
                    {new Date(selectedDate).toLocaleDateString()}
                  </td>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Cut Off:</span>{" "}
                    {new Date(cutOffDate).toLocaleString("en-GB", {
                      timeZone: "UTC",
                    })}
                  </td>
                  <td>
                    <span style={{ fontWeight: 600 }}>Order Confirmed:</span>{" "}
                    {outputDate
                      ? new Date(outputDate).toLocaleString("en-GB", {
                          timeZone: "UTC",
                        })
                      : ""}
                  </td>
                  <td>
                    <span style={{ fontWeight: 600 }}>Your last Checkout:</span>{" "}
                    {amendedDate
                      ? new Date(amendedDate).toLocaleString("en-GB", {
                          timeZone: "UTC",
                        })
                      : ""}
                  </td>
                </tr>
              </div>
              {/* <td>Driver ID: {driverID}</td>
                    <td>Company ID: {companyID}</td> */}
            </tbody>
          </table>
        </Card.Body>
      </Card>

      {formData !== "" && formData !== null && detailLoaded && (
        <div
          style={{ marginBottom: "150px", marginTop: "30px" }}
          className="order-detail-container"
        >
          <OrderDetail
            formData={formData}
            isCutOffPassed={isCutoffPassed}
            orderSummary={orderSummary}
          />
        </div>
      )}

      {/* <div style={{marginBottom:'60px', position: 'fixed', top: 115, width: '100%',  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Button variant="success" size="lg" style={{width:'300px'}} onClick={handleCheckout}> 
              Checkout
          </Button>
      </div> */}
    </div>
  );
}

export default Orders;
