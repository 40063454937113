import React, { useState } from "react";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import "./components.css";
import jmobLogo from "./jmobLogo.png";
import Login from './Login/Login.jsx';

function Navigation(props) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  const driverID = userToken?.driverID;
  const Username = userToken?.driverName;

  const history = useHistory();
  const location = useLocation();

  const logout = () => {
    sessionStorage.removeItem("token");
    window.location.reload(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // check if the user is on "/Login"
  const isLoginPage = location.pathname === "/Login";

  // hide Navigation component if on "/Login"
  if (isLoginPage) {
    return null;
  }

  return (
    <div className="navigation">
      <nav className="navbar navbar-expand-md navbar-dark grid-bg-colour"  style={{ backgroundColor: '#DA291C' }}>

      {/* <img src={jmobLogo} alt="logo" style={{width:'15%'}}/> */}
      <Link to="/" style={{ display: "flex", alignItems: "center" }}>
      <img src={jmobLogo} alt="logo" style={{ width: "70%", height: "auto" }} />
    </Link>


        <div className="container">
          <Link className="navbar-brand" to="/">
            <div >
              {driverID+" "+Username}'s 
            </div>
            <div>
            Online Ordering Portal
            </div>
            
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${
              isMobileMenuOpen ? "show" : ""
            }`}
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto" >
              <li
                className={`nav-item  ${
                  props.location.pathname === "/" ? "active" : ""
                }`}
              >
                <Link className="nav-link" to="/" style={{color:'white'}}>
                  Home
                  <span className="sr-only">(current)</span>
                </Link>
              </li>

              <li>
                <div className="spacer"></div>
              </li>

              <li
                className={`nav-item  ${
                  props.location.pathname === "/Login" ? "active" : ""
                }`}
              >
                <Link className="nav-link" to="/" onClick={logout} style={{color:'white'}}>
                  Sign Out
                </Link>

              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Navigation);
