import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Navigation, Footer } from "./components";
import useToken from "./useToken.js";
import Login from "./components/Login/Login";
import OrderCalendar from "./components/OrderCalendar/OrderCalendar";
import Orders from "./components/Orders/Orders";

function App() {
  const { token, setToken } = useToken();

  if (token && token !== "q>)*8n[TfhTyZAW") {
    alert("Invalid Log-in Token");
    localStorage.removeItem("token");
  }

  return (
    <div className="App">
      <Router>
        <Navigation />
        <div className="main-wrapper">
          {/* 
            switch handles the page routing so you dont have to do anything on NodeJS side
            Routes are added using <Route> required path is added and the component you want to render at
            the listed path is set via exact component.
          */}
          <Switch>
            <Route
              path="/Login"
              exact
              component={() => <Login setToken={setToken} />}
            />
            {token ? (
              <>
                <Route path="/" exact component={() => <OrderCalendar />} />
                <Route path="/Orders" exact component={() => <Orders />} />
              </>
            ) : (
              <Redirect to="/Login" />
            )}
          </Switch>
        </div>
      </Router>
      <div className="d-none d-lg-block">
        <Footer />
      </div>
    </div>
  );
}

export default App;
