import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './styles.css';

const LoadingSpinner = () => {
  return (

    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
