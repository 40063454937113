import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner";
import "./OrderDetail.css";

function OrderDetail({ formData, isCutOffPassed, orderSummary }) {
  const [cols, setCols] = useState(getCols());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showcancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [emailConfirmed, setEmailConfirmed] = useState(
  //   orderSummary?.EmailTickboxDefaultStatus || false
  // );
  // const [PONumber, setPONumber] = useState(orderSummary?.PONumber || "");
  // const [PONumberMandatory, setPONumberMandatory] = useState(
  //   orderSummary?.POMandatory || false
  // );
  // CR: Testing if this will work better
  // const [comment, setComment] = useState(orderSummary?.DriverComment || "");
  // const [commentReadonly, setCommentReadonly] = useState(
  //   orderSummary?.DriverCommentReadOnlyStatus || false
  // );
  const [PONumberMandatory, setPONumberMandatory] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [PONumber, setPONumber] = useState("");
  const [comment, setComment] = useState("");
  const [commentReadonly, setCommentReadonly] = useState(false);
  //const [emailTickboxVisible, setEmailTickboxVisible] = useState(orderSummary?.EmailTickboxDefaultStatus || false);
  const [poEmptyErrorMessage, setPoEmptyErrorMessage] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [totalAmendedItems, setTotalAmendedItems] = useState();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (orderSummary) {
      setPONumberMandatory(orderSummary.POMandatory);
      setEmailConfirmed(orderSummary.EmailTickboxDefaultStatus);
      setPONumber(orderSummary.PONumber);
      setComment(orderSummary.DriverComment);
      setCommentReadonly(orderSummary.DriverCommentReadOnlyStatus);
    }
  }, [orderSummary]);

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  function resetHooks() {
    setCols(getCols());
    setShowConfirmationModal(false);
    setShowSummaryModal(false);
    setShowErrorModal(false);
    setShowCancelModal(false);
    setLoading(false);
    setEmailConfirmed(false);
    setPONumber("");
    setPONumberMandatory(false);
    setComment("");
    setCommentReadonly(false);
    //setEmailTickboxVisible(false);
    setPoEmptyErrorMessage("");
  }

  const handleConfirmationModalCloseAndPushData = () => {
    setShowConfirmationModal(false);
    setShowSummaryModal(false);
    setLoading(true);

    const queryParams = new URLSearchParams(location.search);
    const selectedDate = queryParams.get("orderDate");
    const driverID = queryParams.get("driverID");
    const companyID = queryParams.get("companyID");

    // add selectedDate, driverID, and companyID to the orderSummary object
    const updatedOrderSummary = {
      ...orderSummary,
      selectedDate,
      driverID,
      companyID,
      comment,
      PONumber,
    };

    console.log("updatedOrderSummary : " + JSON.stringify(updatedOrderSummary));
    if (comment) {
    }

    //if items to be amended then push data to routes.js - this is the REST API on the server
    // otherwise give a loading circle for 2 seconds
    fetch("/OrderDetail", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amendedItemsArray: amendedItemsArray,
        orderDetails: {
          driverID: updatedOrderSummary.driverID,
          companyID: updatedOrderSummary.companyID,
          emailConfirm: emailConfirmed,
          PO: updatedOrderSummary.PONumber,
          orderDate: updatedOrderSummary.selectedDate,
          comment: updatedOrderSummary.comment,
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setLoading(false);
          alert("Error updating items : " + JSON.stringify(response.json()));
        }
      })
      .then((data) => {
        setTimeout(() => {
          console.log(data); // Items updated successfully.
          setLoading(false);
          history.push("/");
        }, 1500); // delay for 1.5 seconds
      })
      .catch((error) => {
        console.error(error);
        alert(error);
      });
  };

  const handleSummaryModalClose = () => {
    setShowSummaryModal(false);
  };

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };
  const handleCancelModalCloseAndReturn = () => {
    setShowCancelModal(false);
    history.push("/");
  };

  const handleConfirmationModalOpen = () => {
    if (!PONumber && PONumberMandatory === true) {
      setPoEmptyErrorMessage("PO Number must not be blank");
    } else {
      setPoEmptyErrorMessage("");
      setShowConfirmationModal(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setCols(getCols());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getCols() {
    if (window.innerWidth >= 1200) {
      return 4;
    } else if (window.innerWidth >= 768) {
      return 3;
    } else {
      return 2;
    }
  }

  const [amendedItemsArray, setAmendedItemsArray] = useState([]);
  function handleInputChange(e, amendedItem) {
    const { value } = e.target;
    // check if the SalesCode already exists in the amendedItemsArray
    const existingIndex = amendedItemsArray.findIndex(
      (item) => item.SalesCode === amendedItem.SalesCode
    );
    if (existingIndex !== -1) {
      // update the existing record
      amendedItemsArray[existingIndex].AmendedQty = value;
    } else {
      // add a new record to the array
      amendedItemsArray.push({ ...amendedItem, AmendedQty: value });
    }
    // set the state of the amendedItemsArray
    setAmendedItemsArray(amendedItemsArray);
  }

  // window.addEventListener('beforeunload', (event) => {
  //   // Cancel the event
  //   event.preventDefault();
  //   // Prompt the user with a message
  //   event.returnValue = '';
  //   // Execute your function
  //   alert("TEST")
  // });

  function GetCard(item, index) {
    const [hovered, setHovered] = useState(false);
    return (
      <Col key={item.id} xs={12} sm={6} md={4} xl={3} className="mb-4">
        <div>
          <Card
            bg="light"
            className={`h-100 shadow-sm ${item.Showproduct ? "" : "d-none"}`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onTouchStart={() => setHovered(true)}
            onTouchEnd={() => setHovered(false)}
          >
            {hovered && (
              <span
                style={{
                  textDecoration: "underline",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  marginRight: "20px",
                  fontSize: "20px",
                }}
              >
                {item.Barcode}
              </span>
            )}
            <Card.Header className="font-weight-bold">
              {item.SalesCode} - {item.Description}
            </Card.Header>
            <Card.Body>
              {/* <Card.Title>
                {item.SalesCode}{" "}
              </Card.Title> */}
              <Card.Text>
                {/* {item.SalesCode} - {item.Description} */}
              </Card.Text>
              <Row>
                <Col xs={6} className="text-start">
                  <label>Quantity: {item.Order}</label>
                </Col>
                <Col xs={6} className="text-end">
                  <input
                    type="number"
                    defaultValue={item.AmendedQty}
                    className={`form-control form-control-sm ${
                      isCutOffPassed && "read-only"
                    }`}
                    onChange={(e) => handleInputChange(e, item)}
                    readOnly={isCutOffPassed || !item.UpdateProduct}
                    min="0"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Col>
    );
  }

  //test here
  const difference = amendedItemsArray.reduce((acc, item) => {
    const orderQty = Number(item.OrderQty);
    const amendedQty = Number(item.AmendedQty);
    return acc + (amendedQty - orderQty);
  }, 0);

  const handleCheckout = () => {
    setComment(orderSummary.DriverComment);
    setPONumber(orderSummary.PONumber);

    console.log("Comment : " + comment);
    console.log("PONumber : " + PONumber);
    setTotalItems(
      formData.reduce((acc, item) => {
        return acc + Number(item.Order);
      }, 0)
    );

    const amendedItemsTotal = amendedItemsArray.reduce((total, amendedItem) => {
      const formDataItem = formData.find(
        (item) => item.ProductID === amendedItem.ProductID
      );
      const amendedQtyDiff =
        amendedItem.AmendedQty - (formDataItem ? formDataItem.AmendedQty : 0);
      return total + amendedQtyDiff;
    }, 0);

    setTotalAmendedItems(
      formData.reduce((acc, item) => {
        return acc + Number(item.AmendedQty);
      }, 0) + amendedItemsTotal
    );

    setShowSummaryModal(true);
  };

  const handleCancel = () => {
    if (amendedItemsArray.length > 0) {
      setShowCancelModal(true);
    } else {
      //clear the array
      amendedItemsArray.length = 0;
      history.push("/");
    }
  };

  const rows = [];
  let currentCols = [];

  formData.forEach((item, index) => {
    currentCols.push(GetCard(item));
    if (currentCols.length === cols || index === formData.length - 1) {
      rows.push(<Row key={index}>{currentCols}</Row>);
      currentCols = [];
    }
  });

  return (
    <div>
      {loading && <LoadingSpinner />}
      <Container style={{ marginBottom: 50 }}>
        {formData.length > 0 ? (
          rows
        ) : (
          <Alert variant="danger">No data found</Alert>
        )}

        {/* Butons - Cancel / Checkout */}
        <div className="d-flex align-items-center justify-content-end mt-3">
          <div className="mr-3">
            <Button variant="danger" size="lg" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          {!isCutOffPassed && formData.length > 0 && (
            <div>
              <Button variant="success" size="lg" onClick={handleCheckout}>
                Checkout
              </Button>
            </div>
          )}
        </div>

        <div>
          <Modal
            show={showSummaryModal}
            onHide={handleSummaryModalClose}
            className="custom-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Order Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Original Order Quantity: {totalItems}</p>
              <p>Amended Order Quantity: {totalAmendedItems}</p>
              {amendedItemsArray.length > 0 && (
                <div className="mt-4">
                  <h5>Amended Items:</h5>
                  <ul>
                    {amendedItemsArray.map((item, index) => (
                      <li key={index}>
                        {item.Description} - Quantity ( {item.AmendedQty} )
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="mt-3">
                {true && (
                  <Form.Check
                    type="checkbox"
                    label="Email confirmation"
                    checked={emailConfirmed}
                    onChange={(e) => setEmailConfirmed(e.target.checked)}
                  />
                )}
              </div>
              <div className="mt-4">
                {PONumberMandatory ? (
                  <>
                    <Form.Label>PO Number:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={PONumber}
                      onChange={(e) => setPONumber(e.target.value)}
                    />
                    {poEmptyErrorMessage && PONumberMandatory && (
                      <Alert variant="danger" style={{ marginTop: 10 }}>
                        {poEmptyErrorMessage}
                      </Alert>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <Form.Label>Comment:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  readOnly={commentReadonly}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/*  handleConfirmationModalOpen changing the onclick here so the secondary modal isnt shown */}
              <Button variant="danger" onClick={handleSummaryModalClose}>
                Cancel
              </Button>
              <Button variant="success" onClick={handleConfirmationModalOpen}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <Modal
          show={showConfirmationModal}
          onHide={handleConfirmationModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Order Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to confirm this order?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleConfirmationModalClose}>
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={handleConfirmationModalCloseAndPushData}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showErrorModal} onHide={handleErrorModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Sorry, there was an error processing your request.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleErrorModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showcancelModal} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order Checkout</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to Cancel and return to the landing page ?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleCancelModalCloseAndReturn}>
              Yes
            </Button>
            <Button variant="danger" onClick={handleCancelModalClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default OrderDetail;
