import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
//import moment from 'moment';
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./OrderCalendar.css";
import { useHistory } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

const localizer = momentLocalizer(moment);

function OrderCalendar() {
  //----- GET CURRENT USER -----//
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  const Username = userToken.driverName;
  const [DriverID, setDriverID] = useState(userToken.driverID);
  const DefaultCompany = userToken.defaultCompany;
  const history = useHistory();

  // more useState hooks for variables
  const [CompanyID, setCompanyID] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [datesData, setDates] = useState([]);
  const [datesDataLoaded, setDatesDataLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");
  const [blockedDates, setBlockedDates] = useState([]);
  const [showBlockedDateModal, setShowBlockedDateModal] = useState(false);
  const [blockedDateMessage, setBlockedDateMessage] = useState("");

  // build option list of companies for dropdown form.Control menu
  const [companies, setCompanies] = useState([]);
  async function returnCompanies() {
    const response = await fetch(`/Company?driverID=${DriverID}`);
    const customers = await response.json();
    return customers;
  }

  // this will run once when the component is rendered
  // the blank [] ensures this will run only once,
  // if you wanted this to run again when a variable changes you'd add the variable within the []
  useEffect(() => {
    returnCompanies().then((companies) => {
      setCompanies(companies);
    });
  }, []);

  // function to handle event where dropdown list is changed
  const handleSelect = (event) => {
    const selectedIndex = event.target.value;
    localStorage.setItem("selectedValue", selectedIndex);
    setSelectedValue(parseInt(selectedIndex));
  };

  // Set the selected value from localStorage when component mounts
  useEffect(() => {
    const storedValue = localStorage.getItem("selectedValue");
    if (storedValue !== null) {
      setSelectedValue(parseInt(storedValue));
    } else {
      setSelectedValue(0); // Set default value if localStorage is empty
      localStorage.setItem("selectedValue", "0"); // Save default value in localStorage
    }
  }, []);

  useEffect(() => {
    console.log("selectedValue: " + selectedValue);
    console.log(companies);
    console.log(companies[selectedValue]);

    if (selectedValue !== undefined && companies[selectedValue]) {
      console.log(
        "driverID in useEffect : " + companies[selectedValue].DriverID
      );

      setDriverID(companies[selectedValue].DriverID);

      fetchDates(
        companies[selectedValue].DriverID,
        companies[selectedValue].Company
      ).then((dates) => {
        if (Array.isArray(dates) && dates.length === 0) {
          alert("No dates available.");
        } else {
          setDates(dates);
        }
      });
    }
  }, [selectedValue, companies]);

  // get dates for selected driver and company, this will be used to fill the calendar data
  async function fetchDates(driverID, companyID) {
    console.log("driverID in fetchDates : " + driverID);
    const response = await fetch(
      `/Calendar?driverID=${driverID}&companyID=${companyID}`
    );
    const data = await response.json();

    if (
      JSON.stringify(data) ===
      '{"recordsets":[[]],"recordset":[],"output":{},"rowsAffected":[0]}'
    ) {
      console.log("No data available");
    } else {
      setCompanyID(companyID);
      setDatesDataLoaded(true);
      console.log(JSON.stringify(data));
      return data;
    }
    return [];
  }
  // check if the dates returned are readOnly, do this for entire array of data and relate it aganist the calendar
  const getIsReadOnly = (dateCell) => {
    const validDateObject = datesData.find((date) => {
      const dispatchRealDate = moment(date.DispatchRealDate);
      const dispatchRealDateFormatted = dispatchRealDate.format("YYYY-MM-DD");
      if (dispatchRealDateFormatted === dateCell) {
        return true;
      }
      return false;
    });

    if (validDateObject) {
      const isReadOnly = validDateObject.ReadOnlyStatus === 1;
      return isReadOnly;
    }

    return false;
  };

  const dayStyleGetter = (date) => {
    const dateCell = moment(date).format("YYYY-MM-DD");
    const validDateObject = datesData.find((date) => {
      const dispatchRealDate = moment(date.DispatchRealDate);
      const dispatchRealDateFormatted = dispatchRealDate.format("YYYY-MM-DD");
      if (dispatchRealDateFormatted === dateCell) {
        return true;
      }
      return false;
    });

    if (validDateObject) {
      const isReadOnly = validDateObject.ReadOnlyStatus === 1;
      let backgroundColor = isReadOnly ? "#5ac2e9" : "#5fcc5f";

      const isBlockedDate = checkIfBlockedDate(
        validDateObject.DispatchRealDate
      );

      if (isBlockedDate) {
        backgroundColor = "rgb(151, 151, 151)";
      }

      return { style: { backgroundColor } };
    }

    return { style: { backgroundColor: "#e6e6e6" } };
  };

  const handleDateCellClick = (date) => {
    // NY : Get the date data from the cell thats clicked on the calendar
    // convert this to a string of YYYY-MM-DD and compare to the array of driver data that we stored earlier
    // if the date click is found in the array of data return a value of true to validDate boolean
    // CR: Now using moment timezone to account for daylight savings time
    const dateClicked = moment(date.start)
      .tz("Europe/Dublin")
      .format("YYYY-MM-DD");
    const isReadOnly = getIsReadOnly(dateClicked);
    const validDate = datesData.find((item) => {
      const dispatchRealDate = moment(item.DispatchRealDate).tz(
        "Europe/Dublin"
      );
      const dispatchRealDateFormatted = dispatchRealDate.format("YYYY-MM-DD");
      if (dispatchRealDateFormatted === dateClicked) {
        return true;
      }
      return false;
    });

    //&RO=${isReadOnly}

    // Check if clicked date is a blocked date
    const isBlockedDate = checkIfBlockedDate(dateClicked);

    if (isBlockedDate) {
      const blockedDateMessage = getBlockedDateMessage(dateClicked);
      setBlockedDateMessage(blockedDateMessage);
      setShowBlockedDateModal(true);
      return;
    }

    if (validDate) {
      history.push(
        `/Orders?orderDate=${dateClicked}&driverID=${DriverID}&companyID=${CompanyID}`
      );
    }
  };

  const handleResetButton = async (e) => {
    e.preventDefault();
    setShowFailureAlert(false);
    setShowSuccessAlert(false);
    setShowModal(true);
  };

  //function to handle save click on reset password modal
  // if password is blank inform end user
  // if password and new password are equal do PUT request to the API
  // with new password and driverID
  // inform end user and dispose of popup menu after 1s
  const handleSaveClick = () => {
    if (password === "" || password === null) {
      setFailureMessage("Password cannot be blank");
      setShowFailureAlert(true);
      return;
    }
    if (password === confirmPassword) {
      putUpdatedPassword(DriverID, password);
      setShowFailureAlert(false);
      setShowSuccessAlert(true);

      setTimeout(() => {
        setShowModal(false);
        setPassword("");
        setConfirmPassword("");
      }, 1000);
    } else {
      setFailureMessage("Passwords do not match");
      setShowFailureAlert(true);
    }
  };

  //function to close the popup modal for password reset
  const handleCloseClick = () => {
    setPassword("");
    setConfirmPassword("");
    setShowSuccessAlert(false);
    setShowFailureAlert(false);
    setShowModal(false);
    setShowBlockedDateModal(false);
  };

  //function to push new password data to API - PUT request
  const putUpdatedPassword = (driverID, password) => {
    const data = {
      driverID: driverID,
      password: password,
    };

    fetch("/User", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => console.log(json))
      .catch((error) => console.error(error));
  };

  // CR: Get blocked dates from DB
  useEffect(() => {
    try {
      async function fetchBlockedDates() {
        const response = await fetch(`/blockedDates`);
        const data = await response.json();
        setBlockedDates(data);
        console.log("blocked dates: ", blockedDates);
      }
      fetchBlockedDates();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const checkIfBlockedDate = (date) => {
    if (blockedDates.error) return false;
    let formattedDate = moment(date).format("YYYY-MM-DD");
    let isBlocked = false;
    blockedDates.forEach((blockedDate) => {
      let blockedDay = blockedDate.DispatchRealDate.slice(0, 10);
      let blockedDayCompany = blockedDate.Company;
      let driverCompany = companies[selectedValue].Company;
      if (blockedDay === formattedDate && blockedDayCompany === driverCompany) {
        isBlocked = true;
      }
    });
    return isBlocked;
  };

  const getBlockedDateMessage = (date) => {
    if (blockedDates.error) return "";
    let formattedDate = moment(date).format("YYYY-MM-DD");
    let blockedDateMessage = "";
    blockedDates.forEach((blockedDate) => {
      let blockedDay = blockedDate.DispatchRealDate.slice(0, 10);
      let blockedDayCompany = blockedDate.Company;
      let driverCompany = companies[selectedValue].Company;
      if (blockedDay === formattedDate && blockedDayCompany === driverCompany) {
        blockedDateMessage = blockedDate.Date_Comment;
      }
    });
    return blockedDateMessage;
  };

  // Component render - all html elements to be displayed
  // note if you're trying to render element values into HTML they must be incased within { }
  return (
    <div>
      <Modal show={showModal} onHide={handleCloseClick}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            className="mb-3"
            controlId="passwordInput"
            style={{ margin: "10px" }}
          >
            <Form.Label>Enter New Password</Form.Label>
            <Form.Control
              type="password"
              bg="Secondary"
              placeholder="Enter new password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="confirmPasswordInput"
            style={{ margin: "10px" }}
          >
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              bg="Secondary"
              placeholder="Confirm password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </Form.Group>
          {showSuccessAlert && (
            <Alert
              key="success"
              variant="success"
              onClose={() => setShowSuccessAlert(false)}
              dismissible
            >
              Password reset successful.
            </Alert>
          )}

          {showFailureAlert && (
            <Alert
              key="danger"
              variant="danger"
              onClose={() => setShowFailureAlert(false)}
              dismissible
            >
              {failureMessage}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseClick}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBlockedDateModal} onHide={handleCloseClick}>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Unavailable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fw-bold">JMOB Message:</p>
          <p>{blockedDateMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseClick}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            {/* <Form.Label>Customer Account:</Form.Label> */}
            <Form.Control
              as="select"
              style={{ display: "inline", marginBottom: 10, width: "90%" }}
              onChange={handleSelect.bind(this)}
              selected={selectedValue}
            >
              {companies.map((company, index) => (
                <option
                  key={company.Company}
                  value={index}
                  selected={
                    localStorage.getItem("selectedValue") === null
                      ? company.PrimaryAccount === 1
                      : selectedValue === index
                  }
                >
                  {company.Company} | {company.DriverID} {company.DriverName}
                </option>
              ))}
            </Form.Control>

            <Button
              variant="warning"
              type="submit"
              onClick={handleResetButton}
              style={{ marginBottom: "8px", marginLeft: "5px" }}
            >
              Reset Password
            </Button>
          </Form.Group>
        </Form>
      </div>
      <div
        style={{
          height: "calc(75vh - 100px)", // Adjust the height to leave space for the footer
          width: "100%",
          padding: "0 1%",
          marginBottom: "50px",
          boxSizing: "border-box",
        }}
        className="calendarContainer"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
              backgroundColor: "#5ac2e9",
            }}
          ></div>
          <span style={{ marginRight: "10px" }}>Past Cut Off</span>
          <div
            style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
              backgroundColor: "#5fcc5f",
            }}
          ></div>
          <span style={{ marginRight: "10px" }}>Available</span>
          <div
            style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
              backgroundColor: "#e6e6e6",
            }}
          ></div>
          <span>Unavailable</span>
        </div>
        {datesDataLoaded && (
          <Calendar
            localizer={localizer}
            views={["month"]}
            dayPropGetter={dayStyleGetter}
            selectable={true}
            onSelectSlot={handleDateCellClick}
            longPressThreshold={0}
          />
        )}
      </div>
    </div>
  );
}

export default OrderCalendar;
